import React, { useState, useCallback, useRef, useEffect } from "react";
import Button from "@common/Button/Button";
import {
    SECONDARY_BUTTON,
    MAIN_BUTTON,
    SMALL_SIZE_BUTTON,
    EMAIL_VALIDATION_REGEXP,
} from "@constants";
import arrowIcon from "@images/ic-arrow-right.svg";
import useFormValidation from "@hooks/useFormValidation";
import { isCompanyEmail } from "company-email-validator";
import Input from "../Input/Input";
import "./Form.scss";
import AgreementCheckbox from "../AgreementCheckbox/AgreementCheckbox";
import CVDropzone from "../CVDropzone/CVDropzone";
import SendCV from "../../../../utils/sendCV";

const Form = ({ setShowMessage }) => {
    const defaultState = {
        firstName: "",
        lastName: "",
        email: "",
        message: "",
        topic: "",
        cv: null,
        showErrorMessageForCV: false,
        disabled: true,
    };

    const [state, setState] = useState(defaultState);
    const [isEmailFromCompany, setIsEmailFromCompany] = useState(false);
    const [checkValidation, setFieldValidation] = useFormValidation({
        firstName: false,
        lastName: false,
        email: false,
        message: false,
        topic: false,
    });

    const isEmailBlured = useRef(false);

    const clearForm = () => {
        setState(defaultState);
    };

    // temporary solution
    const freezedState = useRef();
    freezedState.current = state;

    const handleForm = useCallback(() => {
        const body = {
            fields: [
                {
                    name: "firstname",
                    value: freezedState.current.firstName,
                },
                {
                    name: "lastname",
                    value: freezedState.current.lastName,
                },
                {
                    name: "email",
                    value: freezedState.current.email,
                },
                {
                    name: "request",
                    value: freezedState.current.message,
                },
                {
                    name: "subject",
                    value: freezedState.current.topic,
                },
            ],
        };

        if (!checkValidation()) return;

        if (state.topic === "Career") {
            SendCV.sendCV(
                {
                    fullName: `${state.firstName} ${state.lastName}`,
                    email: state.email,
                    message: state.message,
                    cv: state.cv,
                },
                () => {
                    // callback on success
                    clearForm();
                    console.log('success')
                    setShowMessage(true);
                }
            );
        } else {
            return fetch(
                "https://api.hsforms.com/submissions/v3/integration/submit/5461948/99462bec-253b-4612-8e42-1b9910a70eef",
                {
                    method: "POST",
                    body: JSON.stringify(body),
                    headers: {
                        "Content-Type": "application/json",
                    },
                }
            )
                .then(response => {
                    if (response.status >= 200 && response.status < 300) {
                        clearForm();
                        setShowMessage(true);
                        return;
                    }
                    alert("Please, write a valid email");
                })
                .catch(err => err);
        }
    }, [state]);

    const validation = (input, inputName) => {
        const errorMessageNode = input.parentElement.lastChild;
        /* eslint-disable */
    if (input.value.length < 1) {
      input.style.borderColor = "#FF8F35"
      errorMessageNode.style.display = "block"

      setFieldValidation(inputName, false)
    } else if (input.type == "email") {
        if (state.topic.length && state.topic !== "Career") {
            const validStatus = isCompanyEmail(
                input.value
            );
            if (!validStatus) {
                errorMessageNode.style.display = "block"
                input.style.borderColor = "#FF8F35"
                setFieldValidation(inputName, false)
            }    else {
            errorMessageNode.style.display = "none"
            input.style.borderColor = "#FFFFFF"

            setFieldValidation(inputName, true)
        }
        } else {
            if (!EMAIL_VALIDATION_REGEXP.test(input.value) && isEmailBlured.current) {
                errorMessageNode.style.display = "block"
                input.style.borderColor = "#FF8F35"

                setFieldValidation(inputName, false)
            } else {
                errorMessageNode.style.display = "none"
                input.style.borderColor = "#FFFFFF"

                setFieldValidation(inputName, true)
            }
        }
    } else if(input.type == "topic") {
        if(state.topic === "Career" && !state.cv) {
            setFieldValidation(inputName, false)
        }
        else {
            setFieldValidation(inputName, true)
        }
    } else {
      errorMessageNode.style.display = "none"
      input.style.borderColor = "#FFFFFF"

      setFieldValidation(inputName, true)
    }
    /* eslint-enable */
    };

    useEffect(() => {
        if (document) {
            const emailInput = document.querySelector('input[name="email"]');
            if (state.topic.length > 0) {
                validation(emailInput, "email");
                setIsEmailFromCompany(state.topic !== "career");
            }
        }
    }, [state.topic, state.email]);

    useEffect(() => {
        if (state.topic === "Career" && !state.cv) {
            setFieldValidation("topic", false);
        } else if (state.topic) {
            setFieldValidation("topic", true);
        }
    }, [state.cv, state.topic]);

    const handleInputValue = useCallback((inputName, value) => {
        setState(prevState => ({
            ...prevState,
            [inputName]: value,
        }));
    }, []);

    const handleInput = useCallback(
        inputName => e => {
            e.persist();
            handleInputValue(inputName, e.target.value);
            validation(e.target, inputName);
        },
        [handleInputValue]
    );

    return (
        <form className="form">
            <div className="form__inputs-container">
                <div className="form__namesWrapper">
                    <div>
                        <Input
                            name="firstname"
                            placeholder="First name"
                            inputValue={state.firstName}
                            handleInput={handleInput("firstName")}
                            onBlur={e => validation(e.target, "firstName")}
                            errorText="First and last name are required"
                        />
                    </div>
                    <div>
                        <Input
                            name="lastname"
                            placeholder="Last name"
                            inputValue={state.lastName}
                            handleInput={handleInput("lastName")}
                            onBlur={e => validation(e.target, "lastName")}
                            errorText="First and last name are required"
                        />
                    </div>
                </div>
                <Input
                    name="email"
                    type="email"
                    placeholder="Email"
                    inputValue={state.email}
                    handleInput={handleInput("email")}
                    onBlur={e => {
                        isEmailBlured.current = true;
                        validation(e.target, "email");
                    }}
                    errorText={
                        isEmailFromCompany
                            ? "Please, write a company email"
                            : "Hmmm, you sure your email spelled correctly?"
                    }
                />
                <Input
                    name="message"
                    placeholder="Your message"
                    inputValue={state.message}
                    handleInput={handleInput("message")}
                    onBlur={e => validation(e.target, "message")}
                    errorText="So, what do you want to ask or share with us?"
                />
            </div>
            <span className="form__buttons-heading">Choose a topic</span>
            <div className="form__buttons-container">
                <Button
                    text="Product development"
                    type={SECONDARY_BUTTON}
                    size={SMALL_SIZE_BUTTON}
                    handleClick={() =>
                        setState(prevState => ({
                            ...prevState,
                            topic: "product-development",
                        }))
                    }
                    isActive={state.topic === "product-development"}
                />
                <Button
                    text="Dedicated teams"
                    type={SECONDARY_BUTTON}
                    size={SMALL_SIZE_BUTTON}
                    handleClick={() =>
                        setState(prevState => ({
                            ...prevState,
                            topic: "dedicated-teams",
                        }))
                    }
                    isActive={state.topic === "dedicated-teams"}
                />
                <Button
                    text="Partnership"
                    type={SECONDARY_BUTTON}
                    size={SMALL_SIZE_BUTTON}
                    handleClick={() =>
                        setState(prevState => ({
                            ...prevState,
                            topic: "partnership",
                        }))
                    }
                    isActive={state.topic === "partnership"}
                />
                <Button
                    text="Other"
                    type={SECONDARY_BUTTON}
                    size={SMALL_SIZE_BUTTON}
                    handleClick={() =>
                        setState(prevState => ({
                            ...prevState,
                            topic: "other",
                        }))
                    }
                    isActive={state.topic === "other"}
                />
                <Button
                    text="Career"
                    type={SECONDARY_BUTTON}
                    size={SMALL_SIZE_BUTTON}
                    handleClick={() =>
                        setState(prevState => ({
                            ...prevState,
                            topic: "Career",
                        }))
                    }
                    isActive={state.topic === "Career"}
                />
            </div>
            {!state.topic && state.message && (
                <p className="form__buttons-container-error">
                    Please, choose your topic
                </p>
            )}
            {state.topic === "Career" && (
                <CVDropzone
                    setState={setState}
                    state={state}
                    style={{
                        margin: "1.5rem 0",
                    }}
                />
            )}
            <AgreementCheckbox
                handleChange={() =>
                    setState(prevState => ({
                        ...prevState,
                        disabled: !prevState.disabled,
                    }))
                }
            />
            <div className="form__bottom-content-container">
                <Button
                    text="Send"
                    svgPath={arrowIcon}
                    handleClick={handleForm}
                    type={MAIN_BUTTON}
                    isDisabled={state.disabled || !checkValidation()}
                />
                <span className="form__note">
                    *By sending the message, you consent to the processing of
                    personal data
                </span>
            </div>
        </form>
    );
};

export default Form;
