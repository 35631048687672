import { useState } from "react";

export default function useFormValidation(defaultFields) {
    const [validatedFields, setValidatedFields] = useState(defaultFields);

    const setFieldValidation = (inputName, isValidated) => {
        setValidatedFields(prevState => ({
            ...prevState,
            [inputName]: isValidated,
        }));
    };

    const checkValidation = () =>
        Object.values(validatedFields).every(value => value);

    return [checkValidation, setFieldValidation];
}
