import React, { useMemo, useState } from "react";
import "./ContactUs.scss";
import CircleBLur from "@common/CircleBlur/CircleBlur";
import { navigate } from "gatsby";
import { THANK_YOU_PATH } from "@constants";
import Form from "./components/Form/Form";

const ContactUs = () => {
    const [showMessage, setShowMessage] = useState(false);
    const mobilesInfo = useMemo(
        () => [
            {
                country: "UK",
                mobileNumber: "+44 203 286 9449",
            },
            {
                country: "US",
                mobileNumber: "+1 646 956 1919",
            },
        ],
        []
    );

    return (
        <div className="contact-us">
            <CircleBLur width="635px" right="-120px" height="635px" />
            <h1 className="contact-us__heading">
                Start your journey with Impressit
            </h1>
            <div className="contact-us__container">
                {showMessage ? (
                    navigate(THANK_YOU_PATH)
                ) : (
                    <Form setShowMessage={setShowMessage} />
                )}
                <div className="contact-us__info-container">
                    <div className="contact-us__info-address-container">
                        <span className="contact-us__info-address">
                            Headquarter
                        </span>
                        <p className="contact-us__info">
                            5 Zarytskykh St, Lviv, Ukraine, 79011
                        </p>
                    </div>
                    <div className="contact-us__info-address-container">
                        <span className="contact-us__info-address">
                            Representative office
                        </span>
                        <div className="contact-us__info-address-wrapper">
                            <p className="contact-us__info">
                                41 Devonshire Street, Ground Floor, London, United
                                Kingdom, W1G 7AJ
                            </p>
                            <p className="contact-us__info">
                                18 Twarda, Warszawa 00-105, Poland
                            </p>
                        </div>
                    </div>
                    <div className="contact-us__mobile-container">
                        {mobilesInfo.map((mobileInfo, index) => {
                            const { country, mobileNumber } = mobileInfo;
                            return (
                                <div
                                    className="contact-us__mobile-info"
                                    key={index}
                                >
                                    <span className="contact-us__mobile-country">
                                        {country}
                                    </span>
                                    <span className="contact-us__mobile-number">
                                        {mobileNumber}
                                    </span>
                                </div>
                            );
                        })}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ContactUs;
