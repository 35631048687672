import React from "react";
import "./Input.scss";

const Input = ({
    name,
    type = "text",
    placeholder,
    inputValue,
    handleInput,
    onBlur,
    errorText,
}) => (
    <div className="input__container">
        <input
            type={type}
            value={inputValue}
            className="input"
            placeholder={placeholder}
            name={name}
            onChange={handleInput}
            onBlur={onBlur}
        />
        <p>{errorText}</p>
    </div>
);

export default Input;
